<template>
  <section
    id="hero"
  >
    <div class="bg-pixel" />
    <video
      width="100%"
      height="auto"
      loop
      muted
      autoplay
      playsinline
      class="point small"
    >

      <source
        :src="require('@/assets/BS_Start_Mobile_Korr.mp4')"
        type="video/mp4"
      >
      Your browser does not support the video tag.
    </video>
    <video
      width="100%"
      height="auto"
      loop
      muted
      autoplay
      class="point big"
    >

      <source
        :src="require('@/assets/BS_Start_Desktop_Korr.mp4')"
        type="video/mp4"
      >
      <source
        :src="require('@/assets/BS_Start_Mobile_Korr.mp4')"
        type="video/ogg"
      >

      Your browser does not support the video tag.
    </video>
    <div class="bg-pixel-down" />
  </section>
</template>

<script>
  export default {
    name: 'SectionHero',

    provide: {
      theme: { isDark: true },
    },

    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },
  }
</script>
<style scoped>
.bg-pixel {
  background-color: #F0DEDE;
  background-image:url('~@/assets/gradient_red.png');
  background-repeat: repeat-x;
  height:80px;
  background-size:contain;
  width:100%;
  position:relative;
  z-index: 3;
}
.bg-pixel-down {
  background-color: #F0DEDE;
  background-image:url('~@/assets/gradient-black.png');
  background-repeat: repeat-x;
  transform: scaleY(-1);
  height: 80px;
  background-size:contain;
  width:100%;
  display: block;
  position:relative;
  z-index: 3;
}
.point{
  position:relative;
  margin-bottom:-7px;
}
@media screen and (max-width: 600px) {
  .big {
    display:none;
  }
}
@media screen and (min-width: 600px) {
  .small {
    display:none;
  }
}
</style>
